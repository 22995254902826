@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Sen', sans-serif;
    }

}

@layer components {
    .link-navbar {
        @apply text-2xl font-bold text-gray-500 hover:cursor-pointer hover:text-black
    }

    .link-navbar-drawer {
        @apply px-6 py-3 text-xl font-bold text-gray-500
    }

    .list-aboutme-values {
        @apply md:text-3xl font-bold uppercase my-2 text-2xl text-center md:text-left
    }

    .button-aboutme-action {
        @apply bg-secondary-color text-primary-color p-1 rounded-md text-xl font-bold mx-auto w-[250px] block text-center hover:bg-cyan-600 hover:cursor-pointer
    }

    .title-section {
        @apply text-center mt-6 mb-10 text-3xl sm:text-5xl font-bold uppercase text-primary-color mx-4
    }

    .title-skills-section {
        @apply md:text-3xl text-center font-bold mb-6 max-w-[450px] mx-auto text-2xl my-6
    }

    .input-contact-section {
        @apply block w-full p-1 rounded-md text-2xl
    }

    .label-contact-section {
        @apply block text-2xl sm:text-3xl font-bold text-primary-color mt-3 mb-1
    }
}

@layer utilities {
    .text-primary-color {
        color: #F8BB71
    }

    .text-secondary-color {
        color: #245D7E
    }

    .bg-primary-color {
        background-color: #F8BB71
    }

    .bg-secondary-color {
        background-color: #245D7E
    }

    .bg-custom-gradient {
        background-image: linear-gradient(110deg, #F8BB71 70vw, #245D7E 70vw);
        background-color: #245D7E;
    }

    .bg-cusom-gradient-small {
        background-image: linear-gradient(110deg, #F8BB71 94vw, #245D7E 10vw);
        background-color: #245D7E;
    }

    .bg-custom-background-mobile {
        background-color: #F8BB71;
    }

    .bg-statue-md {
        background-image: url("./assets/statue1500x1990m.webp");
        background-repeat: no-repeat;
        background-size: 65vh;
        background-position-x: 76%;
        background-position-y: 100%;
        position: relative;
    }

    .bg-statue-small {
        background-image: url("./assets/statue1500x1990m.webp");
        background-repeat: no-repeat;
        background-size: 35vh;
        background-position-x: 93%;
        background-position-y: 100%;
        position: relative;
    }

}